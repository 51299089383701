<template>
  <div class="con">
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="item">
          <span>姓名：</span>
          <p>{{userInfo.realname}}</p>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="item">
          <span>性别：</span>
          <p>{{userInfo.sex == 1?'男':'女'}}</p>
        </div>
      </el-col>
      <!-- <el-col :span="8">
        <div class="item">
          <span>出生日期：</span>
          <p>{{userInfo.birthday}}</p>
        </div>
      </el-col> -->
      <el-col :span="8">
        <div class="item">
          <span>联系电话：</span>
          <p>{{userInfo.phone}}</p>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="item">
          <span>专业：</span>
          <p>{{userInfo.majorName}}</p>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="item">
          <span>班级：</span>
          <p>{{userInfo.className}}</p>
        </div>
      </el-col>
    </el-row>
    <div class="btn">
      <el-button type="primary" plain @click="goBack">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      userInfo: JSON.parse(localStorage.getItem('ss-userInfo'))
    }
  },
  mounted(){

  },
  methods:{
    goBack() {
      this.$router.back();
    },
  }
}
</script>

<style lang="scss" scoped>
.con{
  padding: 20px 100px;
  margin-top: 30px;
}
.item{
  display: flex;
  margin-bottom: 20px;
  font-size: 16px;
  span{
    width: 90px;
    text-align: right;
    color: #999999;
  }
}
.btn{
  margin-top: 40px;
  text-align: center;
  .el-button{
    width: 100px;
  }
}
</style>