<template>
  <div>
    <el-form 
      v-show="isShowForm" 
      class="login-form" 
      ref="form" 
      :model="form" 
      :rules="wechatRules" 
      label-width="90px"
    >
      <el-form-item label="用户名 : " prop="userName">
        <el-input disabled v-model="form.userName" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item label="密码 : " prop="passWord">
        <el-input v-model="form.passWord" type="password" placeholder="请输入密码" show-password></el-input>
      </el-form-item>
      <el-form-item label="验证码 : " prop="smsCode" class="code">
        <el-input v-model="form.smsCode" placeholder="请输入验证码"> </el-input>
        <div class="identifybox" @click="refreshCode">
          <sidentify :identifyCode="identifyCode"></sidentify>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="d-flex">
          <el-button style="width: 100px" plain type="primary" @click="goBack">返回</el-button>
          <el-button style="width: 100px" type="primary" @click="wechat">确定</el-button>
        </div>
      </el-form-item>
    </el-form>
    <div id="weixin" class="qrcode" v-show="!isShowForm">
      <el-empty :image-size="200" description="您已绑定过微信"></el-empty>
    </div>
  </div>
</template>

<script>
import { bindWeChat, verifyUser } from '@/api/user';
import sidentify from '@/components/identify';
export default {
  components:{
    sidentify
  },
  data(){
    const validateVerifycode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'));
      } else if (value !== this.identifyCode) {
        callback(new Error('验证码不正确!'));
      } else {
        callback();
      }
    };
    return{
      isShowForm:true,
      form: {
        userName: JSON.parse(localStorage.getItem('ss-userInfo')).username,
        passWord: '',
        smsCode: '',
      },
      wechatRules: {
        passWord: {
          required: true,
          pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{9,}$/,
          message: '密码由9位数字、大小写字母和特殊符号组成!',
          trigger: 'blur',
        },
        smsCode: {
          required: true,
          trigger: 'blur',
          validator: validateVerifycode,
        },
      },
      identifyCodes: '1234567890', 
      identifyCode: '', 
    }
  },
  methods:{
    init(){
      this.fetchData();
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    loadmovies() {
      this.isShowForm = false;
      let _this = this;
      new WxLogin({
        id: 'weixin',
        appid: 'wxfecd712b341a1488', // 这个appid要填死
        scope: 'snsapi_login',
        // 扫码成功后重定向的接口  
        redirect_uri: process.env.VUE_APP_WECHAT_URL + '/usercenter',
        // state填写编码后的url
        state: encodeURIComponent(window.btoa('http://127.0.0.1:8080' + _this.$route.path)),
        // 调用样式文件
        // href: "data:text/css;base64,Lyogd3hsb2dpbi5jc3MgKi8NCi5pbXBvd2VyQm94IC50aXRsZSwgLmltcG93ZXJCb3ggLmluZm97DQogIGRpc3BsYXk6IG5vbmU7DQp9DQoNCi5pbXBvd2VyQm94IC5xcmNvZGV7DQogIG1hcmdpbi10b3A6IDIwcHg7DQp9",
      });
    },
    // 生成随机数
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 切换验证码
    refreshCode() {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    async fetchData() {
      let res = await bindWeChat();
      if (res.success) {
        this.result = res.result;
        if (res.result.result.status) {
          this.isShowForm = false;
        } else{
          this.isShowForm = true;
        }
      } else {
        this.$message({
          message: '请求异常',
          type: 'warning',
        });
      }
    },
    wechat() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const { userName, passWord } = this.form;
          let params = {
            userName,
            passWord,
            schoolId: localStorage.getItem('ss-schoolId'),
          };
          let res = await verifyUser(params);
          if (res.success) {
            this.loadmovies();
          } else {
            this.$message({
              message: res.message,
              type: 'warning',
            });
          }
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.back();
    },
  }
}
</script>

<style lang="scss" scoped>
.login-form{
  width: 510px;
  margin: 30px auto;
}
.code {
  /deep/ .el-form-item__content{
    display: flex;
    .el-input__inner {
      flex: 1;
    }
  }
  
}
#weixin {
  display: flex;
  justify-content: center;
}
</style>