<template>
  <div class="wrap">
    <my-header ref="myHeader"></my-header>
    <div class="container">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane name="first">
          <div slot="label" class="user box">个人信息</div>
          <user-info></user-info>
        </el-tab-pane>
        <el-tab-pane name="second">
          <div slot="label" class="edit box">修改密码</div>
          <edit-password></edit-password>
        </el-tab-pane>
        <el-tab-pane name="third">
          <div slot="label" class="box bind">绑定微信</div>
          <bind-weChat ref="bindWeChat"></bind-weChat>
        </el-tab-pane>
      </el-tabs>
    </div>
    <base-footer></base-footer>
  </div>
</template>

<script>
import MyHeader from '@/components/MyHeader.vue';
import BaseFooter from '@/components/BaseFooter.vue';
import UserInfo from "./userInfo.vue"
import EditPassword from "./editPassword.vue"
import BindWeChat from "./bindWeChat.vue"

export default {
  components: { 
    MyHeader, BaseFooter, UserInfo,EditPassword,BindWeChat 
  },
  data() {
    return {
      activeName: 'first',
    };
  },
  methods: {
    handleClick(tab, event) {
      if (tab.index == 2) {
        this.$refs.bindWeChat.init();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.container{
  width: 1300px;
  margin: 0 auto;
  background: #fff;
  min-height: 800px;
  margin-top: 20px;
  padding: 100px 20px 20px;
  box-sizing: border-box;
}
.el-tabs{
  /deep/ .el-tabs__nav {
    left: 50%;
    transform: translateX(-50%) !important;
    border: none;
    .el-tabs__item{
      height: auto;
      line-height: auto;
      margin: 0 100px;
      border-left: none;
      border-bottom: none;
      font-size: 16px;
    }
    .el-tabs__item.is-active{
      border-bottom: 4px solid #409EFF;
    }
    .box{
      width: 76px;
      padding-top: 70px;
      text-align: center;
    }
    .user{
      background: url(~@/assets/img/usercenter/xguser.png) no-repeat top center;
      background-size: 100% auto;
    }
    .edit{
      background: url(~@/assets/img/usercenter/xgpwd.png) no-repeat top center;
      background-size: 100% auto;
    }
    .bind{
      background: url(~@/assets/img/usercenter/xgmm.png) no-repeat top center;
      background-size: 100% auto;
    }

    // .box{
    //   span{
    //     display: block;
    //     font-size: 16px;
        
    //     box-sizing: border-box;
    //   }
    // }
    // .user{
    //   width: 76px;
    //   padding-top: 70px;
    //   background: url(~@/assets/img/usercenter/xguser.png) no-repeat top center;
    //   background-size: 100% auto;
    // }
  }
}
</style>
