<template>
  <el-form 
    class="login-form" 
    ref="ruleForm" 
    :rules="pwdRules" 
    :model="form" 
    label-width="140px"
  >
    <el-form-item label="当前密码: " prop="oldPwd">
      <el-input v-model="form.oldPwd" type="password" placeholder="请输入当前密码" show-password ></el-input>
    </el-form-item>
    <el-form-item label="新密码: " prop="newPwd">
      <el-input v-model="form.newPwd" type="password" placeholder="请输入新密码" show-password></el-input>
    </el-form-item>
    <el-form-item label="确认新密码: " prop="checkPwd">
      <el-input v-model="form.checkPwd" type="password" placeholder="请输入新的密码" show-password></el-input>
    </el-form-item>
    <el-form-item>
      <div class="d-flex">
        <el-button style="width: 140px" plain type="primary" @click="goBack">返回</el-button>
        <el-button style="width: 140px" type="primary" @click="checkPass">确定</el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import { changePassword } from '@/api/user';
export default {
  data(){
    const validatePass = (rule, value, callback) => {
      if (value != this.form.newPwd) {
        callback(new Error('两次密码输入不一致'));
      } else {
        callback();
      }
    };
    return{
      form: {
        oldPwd: '',
        newPwd: '',
        checkPwd: '',
      },
      pwdRules: {
        oldPwd: [
          {
            required: true,
            pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{9,}$/,
            message: '密码由9位数字、大小写字母和特殊符号组成!',
            trigger: 'blur',
          },
        ],
        newPwd: {
          required: true,
          pattern: /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{9,}$/,
          message: '密码由9位数字、大小写字母和特殊符号组成!',
          trigger: 'blur',
        },
        checkPwd: [
          {
            required: true,
            message: '请确认新密码与旧密码是否一致',
            trigger: 'blur',
          },
          { validator: validatePass, trigger: 'blur' },
        ],
      },
    }
  },
  mounted(){

  },
  methods:{
    checkPass() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          let params = {
            id: JSON.parse(localStorage.getItem('ss-userInfo')).id,
            oldPassword: this.form.oldPwd,
            password: this.form.checkPwd,
            username: JSON.parse(localStorage.getItem('ss-userInfo')).username,
          };
          let res = await changePassword(params);
          if (res.success) {
            this.$message({
              message: '修改密码成功',
              type: 'success',
            });
            this.$router.push('/login');
          } else {
            this.$message({
              message: res.message,
              type: 'warning',
            });
          }
        } else {
          return false;
        }
      });
    },
    goBack() {
      this.$router.back();
    },
  },
}
</script>

<style lang="scss" scoped>
.login-form{
  width: 510px;
  margin: 30px auto;
}
</style>